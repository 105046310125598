import skryv from '@skryv/core-ng1';
import { translation, theme } from '@skryv/core-ng1';

import logo from './theme/assets/logo-small-color.png';

window.useESWithFGAs = true;

theme.logo(logo, 'eGovFlowDIY');

translation
  .locale('nl_BE');

(async () => {
  const hash = window.location.hash;
  const dossierId = /#!\/dossier\/([^\/]+)\/?.*/.exec(hash)?.[1];
  if (dossierId) {
    const { shouldRedirect } = await (await fetch("/api/public/backoffice-redirection-config")).json();
    if (shouldRedirect) {
      window.location.replace(`/api/public/backoffice-redirect/dossier/${dossierId}`);
      return;
    }
  }

  skryv
    .customize(theme)
    .customize(translation)
    .initialize(document.getElementById('root'));
})()

